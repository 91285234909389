export default {
	data() {
		return {
			arItens: [],
			arPerfis: [],
			blLoading: true,
			obForm: {
				pesquisa: null,
				idChecklistPerfil: null
			},
			obFormPesquisa: {
				pesquisa: null,
				idChecklistPerfil: null
			}
		}
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get('checklist/listar').then(
				response => {
					this.arItens = response.retorno.itens;
					this.arPerfis = response.retorno.perfis;
					this.blLoading = false;
				}
			)
		},
		filtrar() {
			this.obFormPesquisa.pesquisa = this.obForm.pesquisa;
			this.obFormPesquisa.idChecklistPerfil = this.obForm.idChecklistPerfil;
		},
	},
	computed: {
		listaChecklist() {
			if (!this.arItens) {
				return [];
			}

			return this.arItens.filter(obItem => {
				return (!this.obFormPesquisa.pesquisa ? true :
						(obItem.pergunta.toLowerCase().indexOf(this.obFormPesquisa.pesquisa.toLowerCase()) >= 0 ||
							obItem.documentos.toLowerCase().indexOf(this.obFormPesquisa.pesquisa.toLowerCase()) >= 0)) &&
					(!this.obFormPesquisa.idChecklistPerfil ? true :
						obItem.perfis.some(perfil => {
							return perfil.id == this.obFormPesquisa.idChecklistPerfil
						})
					)
			});
		}
	},
}